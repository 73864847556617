import React from "react"

const FeaturCard4 = ({
  title = "50M+",
  text = "Our Happy Customer",
  icon,
  iconClassName = "",
  className = "",
}) => {
  return (
    <>
      <div
        data-aos="zoom-in"
        className={`relative px-10 py-12  overflow-hidden border rounded-2xl border-neutral-200 ${className}`}
      >
        <div className="flex flex-col gap-2">
          <h6 className="font-bold text-display-sm text-neutral-700">
            {title}
          </h6>
          <p className="font-medium text-body-xl text-neutral-500">{text}</p>
        </div>
        <div
          className={`h-[124px]  flex justify-center items-center  z-10 absolute right-0 top-0 w-[124px] bg-secondary-200 rounded-full translate-x-1/3 -translate-y-1/3 ${iconClassName}`}
        >
          {icon}
        </div>
      </div>
    </>
  )
}
export default FeaturCard4
