import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import ButtonArrow from "../../components/ButtonArrow"

const About5 = () => {
  return (
    <>
      <Section className="overflow-hidden bg-primary-25 ">
        <Container>
          {/* section-wrap */}
          <div className="grid grid-cols-1 laptop:grid-cols-12">
            {/* left-part */}
            <div
              data-aos="fade-up"
              className="col-span-7 pb-16 laptop:pb-0 laptop:pr-[112px]"
            >
              <div>
                <SectionTitle className="">
                  We're building something extra{" "}
                  <span className="text text-primary-600">special</span>
                </SectionTitle>
                <SectionText className="">
                  Bring to the table win-win survival strategies to ensure
                  proactive domination. At the end of the day, going forward, a
                  new normal that has evolved from generation.
                </SectionText>
              </div>
              <Link className="inline-block !pr-2" to="/about-2">
                <ButtonArrow>Explore more</ButtonArrow>
              </Link>
            </div>
            {/* right-part */}
            <div className="col-span-5">
              <div className="relative inline-block">
                <StaticImage
                  src="../../images/about-4.png"
                  alt=""
                  className="relative z-20 rounded-3xl"
                />
                {/* shape1 */}
                <div className="absolute bottom-0 right-0 z-30 inline-block translate-x-1/4 translate-y-1/4">
                  <div
                    data-aos="fade-up-right"
                    className="w-[100px]  h-[100px] bg-primary-50 "
                  >
                    <div className="absolute z-30 bottom-full left-full shape2 w-[48px] h-[48px] bg-secondary-100"></div>
                  </div>
                </div>
                {/* shape3 */}
                <div className="absolute top-0 left-0 z-10 -translate-x-1/2 -translate-y-1/2">
                  <div
                    data-aos="fade-up-left"
                    data-aos-delay="400"
                    className="w-[82px] h-[82px] bg-primary-200"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default About5
