import React from "react"
import { Award, Grid, Heart, Magnet } from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import FeaturCard4 from "../../components/cards/FeatureCard4"
import CountUp from "../../components/CountUp"

const Feature5 = () => {
  return (
    <>
      <Section className="overflow-hidden">
        <Container>
          {/* section-wrap */}
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-12">
            {/* left-part */}
            <div className="col-span-6">
              {/* cards */}
              <div className="grid grid-cols-1 gap-5 tablet:gap-8 tablet:grid-cols-2">
                {/* card */}
                <FeaturCard4
                  className=""
                  title={<CountUp end={50} suffix="M+" />}
                  text="Our Happy Customer"
                  iconClassName="!bg-primary-50 font-bold !text-display-xs text-primary-600"
                  icon={<Heart className="-translate-x-1/2 translate-y-1/2" />}
                ></FeaturCard4>
                <FeaturCard4
                  className=""
                  title={<CountUp end={150} suffix="+" />}
                  text="Our Business Partner"
                  iconClassName="!bg-secondary-50 font-bold !text-display-xs text-secondary-600"
                  icon={<Magnet className="-translate-x-1/2 translate-y-1/2" />}
                ></FeaturCard4>{" "}
                <FeaturCard4
                  className=""
                  title={<CountUp end={50} suffix="+" />}
                  text="Best Service Awards"
                  iconClassName="!bg-error-50 font-bold !text-display-xs text-error-600"
                  icon={<Award className="-translate-x-1/2 translate-y-1/2" />}
                ></FeaturCard4>{" "}
                <FeaturCard4
                  className=""
                  title={<CountUp end={450} suffix="+" />}
                  text="Product Portfolio"
                  iconClassName="!bg-success-50 font-bold !text-display-xs text-success-600"
                  icon={<Grid className="-translate-x-1/2 translate-y-1/2" />}
                ></FeaturCard4>
              </div>
            </div>

            {/* right-part */}
            <div
              data-aos="fade-up"
              className="col-span-6 pt-10 laptop:pt-0 laptop:pl-[100px]"
            >
              <SectionTitle className="">
                Our <span className="text text-primary-600">progress </span>
                is very charming
              </SectionTitle>
              <SectionText className="!pb-0 ">
                Keeping your eye on the ball while performing a deep dive on the
                start-up mentality to derive convergence on cross-platform
                integration.
              </SectionText>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Feature5
