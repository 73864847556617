import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import Hero5 from "../sections/heroes/Hero5"
import Feature5 from "../sections/features/Feature5"
import About5 from "../sections/abouts/About5"
import Client1 from "../sections/clients/Client1"
import Testimonial2 from "../sections/testimonials/Testimonial2"
import Team2 from "../sections/teams/Team2"
import Blog3 from "../sections/blogs/Blog3"
import Contact3 from "../sections/contacts/Contact3"
import Faq1 from "../sections/faqs/Faq1"

const HomePage5 = ({ data }) => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          menuPosition: "left",
          className: "!bg-transparent",
          onScrollClassName: "!bg-neutral-100/95",
        }}
        footerConfig={{
          hasTop: true,
          hasBottomSocial: true,
          bottomClassName: "bg-primary-25",
        }}
      >
        <GatsbySeo
          title={`Kloft - Home 05`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <Hero5 />
        <Feature5 />
        <About5 />
        <Client1 />
        <Testimonial2 className="bg-neutral-50" />
        <Team2 />
        <Blog3 />
        <Contact3 />
        <Faq1 className="bg-neutral-50" />
      </PageWrapper>
    </>
  )
}

export default HomePage5
