import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Hero5Shape from "../../images/svg/hero-5-shape.svg"
import SubscribeForm from "../../components/forms/SubscribeForm"

const Hero5 = () => {
  return (
    <>
      {/* section-wrap */}
      <Section isHero className="overflow-hidden !pb-0">
        <Container>
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-2">
            {/* left-part */}
            <div className="pb-6 laptop:pb-0 laptop:-mt-20">
              <SectionTitle isHero={true}>
                Find a better solution for your{" "}
                <span className="text text-primary-600">business</span>
              </SectionTitle>
              <SectionText isHero={true}>
                Kloft template for startups and business. It is brilliant,
                modular, and easy to use. Lets build your business website now.
              </SectionText>
              <SubscribeForm className="w-[95%] tablet:w-[70%] laptop:w-[70%]" />
            </div>

            {/* right-part */}
            <div className="">
              <div className="relative flex items-end justify-center h-full -z-10 ">
                <Hero5Shape className="absolute bottom-0 left-8 w-full laptop:w-[50vw] h-auto laptop:h-[70vw]" />
                <div className="relative inline-block overflow-hidden laptop:-mt-40">
                  <div className="absolute bottom-0 w-[85%] -translate-x-1/2 translate-y-1/4 left-1/2">
                    <div
                      className="w-full pb-[100%] bg-success-500 rounded-full "
                      data-aos="fade-up"
                      data-aos-delay="1000"
                    ></div>
                  </div>
                  <StaticImage
                    src="../../images/hero-5.png"
                    alt=""
                    className=""
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Hero5
